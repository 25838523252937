@import '../../../../../scss/colors';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';

// BOOK MODAL
.modal-container {
  .book-modal-wrap {
    opacity: 1;
    min-width: initial;

    @include defaultMotion(opacity);

    .modal-content.book-modal {
      padding-top: 28px !important;

      .message {
        margin-bottom: 12px;
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid $c-white-smoke;

        @include flex(flex-start, center, row, nowrap);

        svg {
          margin-right: 6px;

          @include square(20px);

          path {
            fill: $c-hurricane;
          }
        }

        p {
          color: $c-hurricane;
        }
      }

      .book {
        padding: 20px;
        background: $c-almost-white;
        border-radius: 8px;
        width: 100%;

        .book-cover {
          margin-right: 12px;
          img {
            width: 40px;
            height: 60px;
            opacity: 1;
          }
        }

        .book-details {
          .book-author {
            margin-top: 4px;
          }
        }
      }

      .button {
        margin-top: 104px;
        width: calc(100% - 48px);
        position: fixed;
        bottom: 37px;
        left: 50%;
        z-index: 1;

        @include moveX(-50%);
      }
    }
  }

  .book-modal-wrap.loading {
    opacity: 0;
    pointer-events: none;
  }
}

// RESPONSIVE

@include mediaMin($mobile) {
  .modal-container {
    .book-modal-wrap {
      min-width: 520px !important;

      .modal-content.book-modal {
        .book {
          width: 400px !important;

          .book-cover {
            margin-right: 24px !important;
          }
        }

        .button {
          width: 100% !important;
          position: relative !important;
          bottom: auto !important;
        }
      }
    }
  }
}
