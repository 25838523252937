@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

@mixin fieldFont() {
  color: $c-black-swan;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: -0.2px;
}

@mixin fieldFontReg() {
  font-size: 15px !important;
  line-height: 24px !important;
}

// FIELD
.field,
input,
.StripeElement {
  margin-bottom: 12px;
  padding: 0 24px !important;
  display: block;
  width: 100%;
  height: 52px;
  font-size: 14px !important;

  @include fieldFont();
  @include btnSecondary();
}

.field:last-child {
  margin-bottom: 0;
}

input.error,
.field.error,
.StripeElement--invalid {
  border-color: $c-mahogany !important;
}

.field-wrap {
  position: relative;

  .tooltip {
    right: 17px !important;
  }
}

.field-wrap.has-icon {
  svg {
    width: 17.49px;
    height: 17.49px;

    @include position(absolute, 0, 50%, auto, auto);
    @include move(0, -50%);
  }
}

// FIELD WITH LABEL

.field-wrap.has-label {
  margin-bottom: 20px;
  position: relative;

  label {
    font-size: 12px;
    color: $c-grey-strong !important;
    @include position(absolute, 24px, 50%, auto, auto);
    @include move(0, -100%);
  }

  input {
    padding: 22px 24px 6px !important;
    display: block;
    width: 100%;

    @include size(100%, auto);
  }
}

.field-wrap.password-field {
  input {
    padding-right: 66px !important;
  }

  .show-button {
    @include position(absolute, auto, 50%, 20px, auto);
    @include moveY(-50%);
  }
}

// FIELD WITH OPTIONS

.field-wrap.has-options {
  position: relative;
  cursor: pointer !important;

  @include flex(flex-start, center, row, nowrap);

  .field {
    width: 100% !important;
  }

  .field-active {
    span {
      color: $c-hurricane;
    }

    span.selected {
      color: $c-black-swan;
    }
  }
}

.field-options {
  border: 1px solid $c-white-smoke;
  background: $c-white-fang;
  border-radius: 8px;
  width: 100%;
  max-height: 160px;
  overflow-y: scroll;
  opacity: 0;
  pointer-events: none;
  z-index: 1;

  @include position(absolute, 0, auto, auto, 62px);
  @include inputShadow();
  @include defaultMotion(opacity);

  ul {
    li {
      @include defaultMotion(background);
      padding: 0;
      button {
        padding: 16px 20px;
        width: 100%;
      }
    }

    li:hover {
      background: $c-almost-white;
    }
  }
}

.field-options.open {
  opacity: 1;
  pointer-events: auto;
}

.field-options.bottom {
  bottom: auto;
  top: 62px;
}

// FIELD WITH OPTIONS AND LABEL

.field-wrap.has-options.has-label {
  padding: 22px 24px 6px !important;

  label {
    color: $c-black-swan !important;

    @include position(absolute, 24px, 50%, auto, auto);
    @include move(0, -100%);
  }
}

// FIELD WITH OPTIONS AND ICON

.field-wrap.has-options.has-icon {
  img {
    width: 14px;

    @include position(absolute, auto, 50%, 24px, auto);
    @include move(0, -50%);
  }
}

// FIELD WITH SUBMIT

.field-wrap.has-submit {
  position: relative;
  overflow: hidden;
  border-radius: 60px;

  input {
    margin-bottom: 0;
  }

  .field {
    padding-right: 72px !important;
  }

  .submit {
    padding: 0 18px !important;
    width: auto;
    border-radius: 0;
    border: none !important;
    background: none !important;
    color: $c-denim !important;
    cursor: pointer;
    height: calc(100% - 2px);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;

    @include position(absolute, auto, 50%, 0, 0);
    @include moveY(-50%);
    @include removeShadow();
  }
}

.field-wrap.has-options:hover input,
.field-wrap.has-options.open input {
  @include inputShadow();
}

// DISABLED

input:disabled,
input:read-only {
  border-color: $c-border-grey !important;
  background: $c-almost-white !important;
  color: $c-grey-medium !important;

  @include removeShadow();
}

.field-wrap.has-label.disabled label,
.field-wrap.has-label.read-only label {
  color: $c-hurricane !important;
}

// PLACEHOLDER

input:-ms-input-placeholder {
  color: $c-hurricane !important;

  @include fieldFont();
}

input:-moz-placeholder {
  color: $c-hurricane !important;

  @include fieldFont();
}

input::-moz-placeholder {
  color: $c-hurricane !important;

  @include fieldFont();
}

input::-webkit-input-placeholder {
  color: $c-hurricane !important;

  @include fieldFont();
}

input::placeholder {
  color: $c-hurricane !important;

  @include fieldFont();
}

// NUMBERS

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// DARK

.field-wrap.dark {
  input {
    background: $c-grey-strong !important;
    border-color: $c-grey-strong !important;
    color: $c-white-fang !important;
  }

  input:hover,
  input:focus {
    @include removeShadow();
  }

  input:-ms-input-placeholder {
    color: $c-grey-medium !important;
  }

  input:-moz-placeholder {
    color: $c-grey-medium !important;
  }

  input::-moz-placeholder {
    color: $c-grey-medium !important;
  }

  input::-webkit-input-placeholder {
    color: $c-grey-medium !important;
  }

  input::placeholder {
    color: $c-grey-medium !important;
  }
}

// RESPONSIVE

@include mediaMin($mobileS) {
  .field,
  input,
  .StripeElement {
    @include fieldFontReg();
  }

  input:-ms-input-placeholder {
    color: $c-hurricane !important;

    @include fieldFontReg();
  }

  input:-moz-placeholder {
    color: $c-hurricane !important;

    @include fieldFontReg();
  }

  input::-moz-placeholder {
    color: $c-hurricane !important;

    @include fieldFontReg();
  }

  input::-webkit-input-placeholder {
    color: $c-hurricane !important;

    @include fieldFontReg();
  }

  input::placeholder {
    color: $c-hurricane !important;

    @include fieldFontReg();
  }
}
