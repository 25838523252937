// IMPORTS
@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// SEARCH BAR
.search-bar {
  position: relative;

  input {
    padding: 0 30px 0 $subheaderHeight;
    border-radius: 100%;
    background: $c-white-fang;

    @include size(100%, $subheaderHeight);
  }

  input:hover,
  input:focus {
    border-color: $c-search-input-hover !important;
    @include searchInputShadow();
  }
}

.clear-icon {
  opacity: 0;
  pointer-events: none;
  cursor: pointer;

  @include position(absolute, auto, 50%, 20px, 0);
  @include move(0, -50%);
  @include defaultMotion(opacity);

  svg {
    @include size(24px, 24px);

    path {
      fill: $c-grey-medium;

      @include defaultMotion(fill);
    }
  }
}

.clear-icon:hover {
  svg {
    path {
      fill: $c-black-swan;
    }
  }
}

.clear-icon.show {
  opacity: 1;
  pointer-events: auto;
}

// DYNAMIC SEARCH BAR
.search-bar.dynamic {
  padding: 0 20px;
  z-index: 1;

  input {
    margin-bottom: 0;
    padding-left: 28px !important;
    border: none !important;
    border-radius: 0 !important;
    background: none !important;
    position: relative;
    top: -1px;

    @include removeShadow();
  }

  .search-results-container {
    padding: $subheaderHeight 0 0;
    background: $c-white-fang;
    border: 1px solid $c-white-smoke;
    border-radius: 26px;
    overflow: hidden;
    z-index: -1;

    @include size(100%, 0);
    @include position(absolute, 0, -2px, auto, auto);
    @include customMotion(all, $easeInOutCubic, 0.3s);

    .search-results {
      padding-top: 12px;
      overflow-y: scroll;
      height: calc(436px - #{$subheaderHeight});
    }

    .search-results:before {
      content: '';

      background: $c-white-smoke;

      @include size(calc(100% - 40px), 1px);
      @include position(absolute, 50%, $subheaderHeight, auto, auto);
      @include move(-50%, 0);
    }
  }

  .search-results-container.expand {
    height: 436px;
    border-color: $c-search-input-hover !important;

    @include forceSearchInputShadow();
  }

  .search-results-container.reduce {
    height: 104px;
  }
}
