@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// MARKETING - GLOBAL STYLES
.marketing-app {
  * {
    -webkit-font-smoothing: auto;
  }

  .markdown {
    word-break: break-word;
    font-size: 14px;

    h1,
    h2,
    h3,
    p,
    li {
      color: rgba(0, 0, 0, 0.87);
    }

    h2 {
      margin: 0.7em 0 0.4em;
      font-size: 3.5em !important;
      line-height: 110% !important;
    }

    h3 {
      margin: 0.6em 0 0.3em;
      font-size: 2.8em;
      line-height: 110% !important;
    }

    p,
    li {
      font-size: 15px !important;
      line-height: 22.5px !important;
      list-style-type: disc;
    }

    ul {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 2.5em;
    }

    p {
      margin: 15px 0;
    }

    ol {
      padding-left: 40px;
    }

    a:after {
      display: none;
    }
  }

  .terms,
  .privacy {
    word-break: break-word;
  }

  .fs26 {
    font-size: 26pt;
  }
  .fs22 {
    font-size: 22pt;
  }

  /* Layout */
  .s5ths,
  .m5ths,
  .l5ths,
  .xl5ths {
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s5ths {
    width: 20%;
  }
  @media only screen and (min-width: 601px) {
    .row .col.m5ths {
      width: 20%;
    }
  }
  @media only screen and (min-width: 993px) {
    .row .col.l5ths {
      width: 20%;
    }
  }
  @media only screen and (min-width: 1201px) {
    .row .col.xl5ths {
      width: 20%;
    }
  }

  /* Mobile Navigation */
  .mobile-nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/graphics/mobile-nav-menu-bg.svg');
    background-color: #064c37;
    background-size: cover;
    z-index: 1;
  }
  .mobile-nav-overlay.visible {
    transition-timing-function: ease-out;
    transition: 0.4s;
    transform: translateX(0);
  }
  .mobile-nav-overlay.hidden {
    transition-timing-function: ease-in;
    transition: 0.4s;
    transform: translateX(100%);
  }
  .dots-dark {
    display: none;
  }
  body.blog-detail .dots-light,
  body.blog .dots-light {
    display: none;
  }
  body.blog-detail .dots-dark,
  body.blog .dots-dark {
    display: block;
  }
  #mobile-nav-overlay-open img {
    position: absolute;
    top: 0;
    right: -18px;
    height: 42px;
  }

  .mobile-nav-overlay-header {
    padding: 20px 0 0 0;
    margin: 0 18px;
    height: 60px;
  }
  .mobile-nav-overlay-logo {
    width: 120px;
  }
  .mobile-nav-overlay-heading {
    color: #ffeaef;
    font-size: 14px;
    margin-top: 33px;
  }
  .mobile-nav-overlay-links {
    padding-top: 29px;
    margin: 0 18px;
    width: auto;
  }
  .mobile-nav-overlay-link {
    padding: 4px 0;
  }
  .mobile-nav-overlay-link a {
    color: white;
    font-size: 20px;
    line-height: 50px;
    position: relative;
    display: block;
    margin-left: -18px;
  }
  .mobile-nav-overlay-link a:first-child p {
    margin: 0;
    padding-left: 18px;
  }
  .mobile-nav-overlay-link a.active::before {
    content: '';
    display: block !important;
    width: 4px;
    height: 100%;
    background: #e4adbb;
    border-radius: 0 50px 50px 0;
    position: absolute;
  }

  .mobile-nav-overlay-social {
    margin-right: 28px;
    padding-left: 18px;
  }

  /* Site Footer */
  .footer-copyright a,
  .footer-copyright {
    color: #929091;
  }
  .footer-mailing-list-container .container .col.hide-on-small-and-down {
    width: 40%;
    min-width: 395px;
  }
  @media only screen and (max-width: 900px) {
    .footer-mailing-list-container .container .col.hide-on-small-and-down {
      width: 51%;
      min-width: 0;
    }
  }
  .footer-mailing-list-container .container,
  .footer-mailing-list-container {
    background-image: none;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
  }
  .footer-mailing-list-container {
    background-image: url('../../assets/images/graphics/art-Abstract.webp');
    background-color: #ffc717;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  @media only screen and (min-width: 1400px) {
    .footer-mailing-list-container .container {
      background-size: 100% auto;
      background-image: url('../../assets/images/graphics/art-Abstract.webp');
    }
    .footer-mailing-list-container {
      background-image: none;
    }
  }
  @media only screen and (max-width: 600px) {
    .footer-mailing-list-container {
      background-image: url('../../assets/images/graphics/art-Abstract-Mobile.webp');
      background-size: cover;
    }
    .footer-mailing-list-container .container {
      background-image: none;
      min-height: 860px;
    }
    .footer-mailing-list-container h3 {
      font-size: 22pt;
    }
    .footer-mailing-list-container form {
      max-width: 70%;
      margin: auto;
    }
  }
  .social-media-buttons img {
    width: 25px;
    filter: invert(95%) sepia(1%) saturate(376%) hue-rotate(277deg)
      brightness(60%) contrast(93%);
  }
  .social-media-buttons img:hover {
    filter: invert(95%) sepia(1%) saturate(376%) hue-rotate(277deg)
      brightness(80%) contrast(93%);
  }
  .icon-block {
    padding: 0 15px;
  }
  .footer-copyright a:hover {
    color: #ededed;
  }

  /* Home */
  .main-hero {
    background-image: url('../../assets/images/graphics/art-HeroIllustration.webp');
    min-height: 610px;
    background-size: 108%;
    background-position: center bottom -1vw;
    background-color: #004a33;
    background-repeat: no-repeat;
    height: 51vw;
    max-height: 40vw;
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    .main-hero {
      background-image: url('../../assets/images/graphics/art-HeroIllustration-Mobile.webp');
      min-height: 640px;
      background-size: 108% auto;
      background-position: center bottom -2vw;
    }
  }
  .section .col.founder-letter {
    padding-top: 30px;
  }
  @media only screen and (min-width: 600px) {
    .section .col.founder-letter {
      padding-left: 40px;
      padding-right: 0;
    }
  }
  .join-waitlist-button button {
    font-size: 13pt;
    background-color: #ffeaef;
    margin-top: 16px;
    padding: 15px 36px;
    border: 0;
    border-radius: 25px;
    cursor: pointer;
  }
  .submit-button {
    height: 47px;
    margin-top: -8px;
    background: #160f16;
    border: none;
    color: white;
    width: 120px;
    cursor: pointer;
  }

  /* About */
  .about-team-pics {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
    max-width: 1000px;
  }
  .about-team-pics .pic {
    flex: 0 1 auto;
    align-self: auto;
    padding: 0 7px 50px 0;
  }
  .about-team-pics .pic p {
    min-width: 157px;
    margin: 0;
    font-family: 'Inter', sans-serif;
  }
  .about-team-pics .pic h6 {
    font-family: 'Inter', sans-serif;
    font-weight: bold !important;
  }
  .about-team-pics .name {
    margin-top: 0;
    font-size: 1rem;
  }
  .about-team-pics .dept {
    font-size: 0.9rem;
    color: #847f7c;
  }
  .about-team-pics img {
    width: 90%;
    max-width: 157px;
    border-radius: 50%;
    margin-bottom: 16px;
  }
  .about-principles {
    margin-bottom: 0;
  }
  .about-principles h3 {
    font-size: 23pt;
  }
  @media only screen and (max-width: 600px) {
    .about-principles h3 {
      font-size: 7.5vw;
    }
  }
  .about-principles .col {
    min-height: 220px;
  }
  .about-principles .col:last-child {
    min-height: 0px;
  }
  .about-view-positions-button {
    background: #3f8b55;
    color: white;
    padding: 20px 25px 20px 25px;
    border-radius: 25px;
  }

  /* Navigation */
  nav {
    background-color: #004a33;
    padding: 21px;
    padding-top: 26px;
    height: auto;
  }
  .logo-dark {
    display: none;
  }
  body.blog .logo-light,
  body.blog-detail .logo-light {
    display: none;
  }
  body.blog .logo-dark,
  body.blog-detail .logo-dark {
    display: inline;
  }
  body.blog nav,
  body.blog-detail nav {
    box-shadow: none;
  }
  body.blog nav {
    background-color: rgba(117, 214, 255, 0.3);
  }
  body.blog-detail nav {
    background-color: transparent;
  }
  .nav-links {
    padding-top: 2px;
    position: absolute;
    right: 0;
    font-size: 16px;
  }
  .nav-links-item {
    display: inline;
    padding: 11px 17px 10px 17px;
  }
  .nav-links-item:last-child {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  body.blog .nav-links-item:last-child,
  body.blog-detail .nav-links-item:last-child {
    border-left-color: rgba(0, 0, 0, 0.2);
  }
  .nav-links-anchor {
    color: #f9f9f9;
    line-height: 24px;
    position: relative;
    padding-bottom: 18px;
  }
  body.blog .nav-links-anchor,
  body.blog .nav-links-anchor svg path,
  body.blog-detail .nav-links-anchor,
  body.blog-detail .nav-links-anchor svg path {
    color: #161015;
    fill: #161015;
  }
  .nav-links-anchor:hover,
  .nav-links-anchor:hover svg path {
    color: #0a8e4f;
    fill: #0a8e4f;
  }
  .nav-links-anchor.active::after {
    opacity: 1;
  }
  .nav-links-anchor::after {
    content: '';
    display: block !important;
    width: calc(100%);
    height: 4px;
    background: #0a8e4f;
    border-radius: 50px 50px 0 0;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: calc(100% + 2px);
    right: auto;
    bottom: 0;
    transform: translate(-50%);
    transition: opacity 0.3s ease;
  }
  .nav-join-waitlist {
    background: white;
    padding: 10px 17px;
    border-radius: 50px;
    margin-left: 7px;
    color: #161015;
  }
  body.blog .nav-join-waitlist,
  body.blog-detail .nav-join-waitlist {
    background-color: #161015;
    color: white;
  }

  /* Icons */
  .icon {
    display: inline-block;
    text-decoration: none !important;
    vertical-align: middle;
    cursor: pointer;
  }
  .icon-close {
    width: 14px;
    height: 14px;
  }
  .icon-chevron {
    width: 10px;
    height: 6px;
    position: relative;
    top: -1px;
    left: 3px;
  }
  .flip-y {
    transform: scaleY(-1);
  }

  /* Sign-Up */
  .next-step-button {
    font-size: 13pt;
    background-color: #160f16;
    color: #fff;
    margin-top: 16px;
    padding: 15px 36px;
    border: 0;
    border-radius: 25px;
    cursor: pointer;
    white-space: nowrap;
  }
  .success-message {
    background-color: #0a8e4f;
    color: white;
    border-radius: 5px;
    padding: 20px;
    font-weight: 300;
    text-align: left;
  }

  /* Blog Masthead */
  .blog-masthead {
    height: 429px;
    background-color: rgba(117, 214, 255, 0.3);
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    text-align: center;
  }
  .blog-masthead-content {
    position: relative;
    top: 125px;
  }
  .blog-masthead-content h1 {
    margin: 0;
  }
  .blog-tagline {
    font-size: 16px;
    max-width: 415px;
    margin: 0 auto;
    position: relative;
    top: 11px;
    color: #161015;
    padding: 0 42px;
  }

  /* Blog Container & Posts */
  .blog-container {
    text-align: center;
    width: 87%;
    margin-bottom: 120px;
  }
  .blog-posts {
    margin: 40px auto 0 auto;
    width: 520px;
    list-style: none;
    padding: 0;
  }
  .blog-post {
    width: 520px; /*width: 100%;*/
    padding-top: 60px;
    page-break-inside: avoid;
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    display: table;
  }
  .blog-post:first-child {
    padding-top: 0;
  }
  .blog-post-media {
    height: 280px;
    border-radius: 8px;
    border-bottom-right-radius: 100px;
    position: relative;
    overflow: hidden;
  }
  .blog-media-photo {
    display: list-item;
    width: 100%;
    height: auto;
  }
  .blog-post-info {
    text-align: left;
    margin-top: 20px;
  }
  .blog-post-tags {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #064c37;
  }
  .blog-post-tags .icon {
    position: relative;
    margin-right: 5px;
  }
  .blog-post-tags .icon-video {
    width: 13px;
    top: -1px;
  }
  .blog-post-tags .icon-recommendations {
    width: 13px;
    top: -2px;
  }
  .blog-post-tags .icon-folios {
    width: 9px;
    top: -2px;
  }
  .blog-post-wellness .icon-folios {
    width: 14px;
    top: -2px;
  }
  .blog-post-title a {
    color: #161015;
  }
  .blog-post-title a:hover {
    text-decoration: underline;
  }
  .blog-post-title h2 {
    font-size: 32px;
    line-height: 36px;
    margin: 12px 0 0 0;
  }
  .blog-post-excerpt {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2e272d;
  }
  .blog-post-details {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    color: #d1cfd0;
    margin-top: 16px;
    letter-spacing: 0.01em;
  }
  .blog-post-author {
    float: left;
    color: #e4adbb;
  }
  .blog-post-author::after {
    content: '•';
    margin: 0 6px;
  }
  .blog-post-date {
    float: left;
  }

  /* Blog Tag Selector */
  .blog-tag-selector {
    margin: 47px auto 0 auto;
  }
  .blog-tag-item {
    border-radius: 52px;
    background-color: #f9f9f9;
    color: #847f7c;
    font-size: 16px;
    padding: 12px 26px;
    margin-right: 8px;
  }
  .blog-tag-item:last-child {
    margin-right: 0;
  }
  .blog-tag-item.active {
    background-color: #064c37;
    color: white;
  }
  .blog-tag-item .icon-folios {
    width: 12px;
    position: relative;
    top: -2px;
  }
  .blog-tag-item .icon-wellness {
    width: 15px;
    position: relative;
    top: -2px;
  }
  .blog-tag-item .icon-recommendations {
    width: 18px;
    position: relative;
    top: -2px;
  }
  .blog-tag-item .icon-video {
    width: 17px;
    position: relative;
    top: -2px;
  }

  @media only screen and (max-width: 600px) {
    .blog-container {
      min-width: 328px;
    }
    .blog-posts {
      width: 100%;
    }
    .blog-post {
      width: 100%;
    }
    .blog-post-media {
      height: auto;
    }
  }
  @media only screen and (min-width: 1072px) {
    .blog-container {
      min-width: 1072px; /* 520 + 32 + 520 */
    }
    .blog-masthead-content h1 {
      font-size: 100px;
    }
    .blog-tagline {
      padding: 0;
    }
    .blog-container {
      width: auto;
    }
    .blog-posts {
      column-count: 2;
      column-gap: 32px;
      width: 1072px;
    }
    .blog-post {
      min-width: 520px;
      padding-top: 80px;
    }
    .blog-post:first-child {
      column-span: all;
      width: 100%;
    }
    .blog-post:first-child .blog-post-media {
      width: calc(50% - 16px);
      float: left;
      margin-right: 32px;
    }
    .blog-post:first-child .blog-post-info {
      width: calc(50% - 16px);
      float: left;
    }
    .blog-post:first-child .blog-post-title h2 {
      font-size: 40px;
      line-height: 44px;
    }
    .blog-post:first-child .blog-post-info {
      margin-top: 0;
    }
  }

  .start-hidden {
    display: none;
  }
  .error {
    color: lightsalmon;
  }

  /* Waiting List */
  .waiting-container {
    background-color: #f4c635;
    width: 100%;
    max-width: none;
    height: 760px;
    margin: 0;
    position: relative;
    text-align: center;
    padding-top: 120px;
  }
  .waiting-container h1 {
    color: #ffffff;
    font-size: 52px;
    line-height: 56px;
    max-width: 700px;
    margin: 0 auto;
  }
  .waiting-top {
    background-image: url('../../assets/images/graphics/bg-waiting-top.webp');
    background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    display: none;
    top: 0;
  }
  .waiting-bottom {
    background-image: url('../../assets/images/graphics/bg-waiting-bottom.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .waitlist-controls {
    position: absolute;
    left: calc(50% - 400px);
    transform: translateX(50%);
    margin-top: 44px;
    width: 400px;
    height: 52px;
  }
  #waitlist-input {
    background: white;
    border: none;
    width: 352px;
    height: 18px;
    border: 1px solid #ededed;
    border-radius: 52px;
    padding: 16px 24px;
    font-size: 15px;
    line-height: 24px;
    position: absolute;
    left: 0;
  }
  #waitlist-input::placeholder {
    color: #847f7c;
  }
  #waitlist-input:focus {
    box-shadow: none;
  }
  #waitlist-submit {
    background: transparent;
    border: none;
    font-size: 16px;
    padding: 18px;
    position: absolute;
    right: 0;
    line-height: 0px;
    border-radius: 30px;
    color: #116ecd;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    height: 51px;
    top: 1;
  }
  #waitlist-submit:focus {
    outline: none;
  }
  .waitlist-disclaimer {
    color: #c88709;
    margin: 0 auto;
    max-width: 400px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03em;
    position: absolute;
    bottom: -52px;
    height: 52px;
    padding-top: 30px;
  }

  @media only screen and (max-width: 600px) {
    .waiting-container {
      padding-top: 170px;
    }
    .waiting-container h1 {
      font-size: 40px;
      line-height: 40px;
      max-width: 328px;
    }
    .waiting-top {
      display: block;
    }
    .waitlist-controls {
      left: calc(50% - 256px);
      width: 256px;
    }
    #waitlist-input {
      width: 208px;
    }
    .waitlist-disclaimer {
      max-width: 328px;
    }
  }

  /* Blog Detail Page */
  .blog-detail-container {
    text-align: center;
    width: 87%;
    margin-bottom: 120px;
    max-width: 888px;
  }
  .blog-detail-tags {
    margin: 46px auto 0 auto;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #064c37;
  }
  .blog-detail-tags .icon {
    position: relative;
    margin-right: 5px;
  }
  .blog-detail-tags .icon-video {
    width: 13px;
    top: -1px;
  }
  .blog-detail-title h2 {
    margin: 12px 0 0 0;
  }
  .blog-detail-details {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    color: #d1cfd0;
    margin: 12px auto 47px auto;
    letter-spacing: 0.01em;
  }
  .blog-detail-media {
    display: block;
    position: relative;
  }
  .blog-detail-youtube {
    width: 100%;
    height: auto;
  }
  .blog-detail-youtube #ytplayer {
    display: none;
  }
  .blog-detail-media .thumbnail-video {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  .blog-detail-media .icon-video {
    width: 84px;
    height: 84px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    position: absolute;
  }
  .blog-detail-author {
    color: #e4adbb;
  }
  .blog-detail-author::after {
    content: '•';
    margin: 0 6px;
  }
  .blog-detail-excerpt {
    margin: 60px auto 0 auto;
    font-size: 24px;
    line-height: 32px;
    max-width: 704px;
    text-align: left;
  }
  .blog-detail-content {
    text-align: left;
    max-width: 704px;
    margin: 35px auto 0 auto;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2e272d;
  }
  .blog-detail-speaker {
    text-align: left;
    margin: 40px auto 0 auto;
    border-top: 1px solid #ededed;
    max-width: 704px;
  }
  .blog-detail-speaker-heading {
    margin-top: 39px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #d1cfd0;
  }
  .blog-detail-speaker-heading .icon {
    position: relative;
    margin-right: 5px;
  }
  .blog-detail-speaker-heading .icon-speaker {
    width: 12px;
    top: -2px;
  }
  .blog-detail-speaker-avatar {
    font-size: 16px;
    line-height: 22px;
    color: #2e272d;
    margin-top: 22px;
  }
  .blog-detail-speaker-avatar img {
    width: 44px;
    height: 44px;
    vertical-align: middle;
    margin-right: 8px;
  }
  .blog-detail-speaker-content {
    text-align: left;
    max-width: 704px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2e272d;
    margin: 10px auto 0 auto;
  }
  .blog-detail-author-info {
    text-align: center;
    margin: 40px auto 0 auto;
    border-top: 1px solid #ededed;
    max-width: 704px;
  }
  .blog-detail-author-avatar {
    margin: 59px auto 0 auto;
    color: #064c37;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
  }
  .blog-detail-author-avatar img {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-right: 8px;
  }
  .blog-detail-author-info p {
    max-width: 520px;
    margin: 16px auto 0 auto;
    text-align: left;
    color: #847f7c;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  @media only screen and (max-width: 600px) {
    .blog-detail-container {
      min-width: 328px;
    }
    .blog-detail-title h2 {
      font-size: 40px;
      line-height: 40px;
    }
    .blog-detail-excerpt {
      font-size: 16px;
      line-height: 22px;
      margin-top: 40px;
    }
    .blog-detail-speaker-heading {
      margin-top: 60px;
    }
  }
}

/* FOUC Fix: Keep this at the bottom. */
html {
  visibility: visible;
  opacity: 1;
}

// RESPONSIVE

@include mediaMin($mobile) {
  .markdown {
    font-size: 16px !important;
  }
}
