// IMPORTS
@import 'colors';
@import 'mixins';
@import 'vars';

/*

INDEX

1 | CONTAINERS
2 | MOTION
3 | BUTTONS
4 | LINKS
5 | RESPONSIVE

*/

// TYPOGRAPHY

// HELDANE
@font-face {
  font-family: 'Heldane Display';
  src: url('../assets/fonts/heldane/HeldaneDisplay-Medium.eot');
  src: url('../assets/fonts/heldane/HeldaneDisplay-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/heldane/HeldaneDisplay-Medium.woff2') format('woff2'),
    url('../assets/fonts/heldane/HeldaneDisplay-Medium.woff') format('woff'),
    url('../assets/fonts/heldane/HeldaneDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heldane Display';
  src: url('../assets/fonts/heldane/HeldaneDisplay-Regular.eot');
  src: url('../assets/fonts/heldane/HeldaneDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/heldane/HeldaneDisplay-Regular.woff2') format('woff2'),
    url('../assets/fonts/heldane/HeldaneDisplay-Regular.woff') format('woff'),
    url('../assets/fonts/heldane/HeldaneDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heldane Display';
  src: url('../assets/fonts/heldane/HeldaneDisplay-Bold.eot');
  src: url('../assets/fonts/heldane/HeldaneDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/heldane/HeldaneDisplay-Bold.woff2') format('woff2'),
    url('../assets/fonts/heldane/HeldaneDisplay-Bold.woff') format('woff'),
    url('../assets/fonts/heldane/HeldaneDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// DES MONTILLES
@font-face {
  font-family: 'Des Montilles';
  src: url('../assets/fonts/des_montilles/DesMontilles-Regular.eot');
  src: url('../assets/fonts/des_montilles/DesMontilles-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/des_montilles/DesMontilles-Regular.woff2')
      format('woff2'),
    url('../assets/fonts/des_montilles/DesMontilles-Regular.woff')
      format('woff'),
    url('../assets/fonts/des_montilles/DesMontilles-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// PALMER LAKE PRINT
@font-face {
  font-family: 'Palmer Lake Print';
  src: url('../assets/fonts/palmer_lake_print/PalmerLakePrint-Regular.eot');
  src: url('../assets/fonts/palmer_lake_print/PalmerLakePrint-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/palmer_lake_print/PalmerLakePrint-Regular.woff2')
      format('woff2'),
    url('../assets/fonts/palmer_lake_print/PalmerLakePrint-Regular.woff')
      format('woff'),
    url('../assets/fonts/palmer_lake_print/PalmerLakePrint-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// BUBBLEGUM
@font-face {
  font-family: 'BubbleGum';
  src: url('../assets/fonts/bubblegum/BubbleGum.eot');
  src: url('../assets/fonts/bubblegum/BubbleGum.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/bubblegum/BubbleGum.woff2') format('woff2'),
    url('../assets/fonts/bubblegum/BubbleGum.woff') format('woff'),
    url('../assets/fonts/bubblegum/BubbleGum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* --- 1 | CONTAINERS --- */

.wf-loading .app,
.app {
  position: relative;
  opacity: 0;
  pointer-events: none;

  @include defaultMotion(opacity);
}

.wf-active .app.active {
  opacity: 1;
  pointer-events: auto;
}

.app:before {
  content: '';
  clear: both;
  display: table;
  margin-top: -1px;
  height: 0;
}

.app-content {
  position: relative;
  z-index: 1;
}

.wrap {
  margin: 0 auto;
  padding: 0 $edgeMobile;
  border-radius: 0;
  background: $c-fable-green;

  @include size(100%, auto);
}

.screen-height {
  min-height: 100vh;
}

.wrap.fill {
  padding: 100px 0 60px;
  min-height: initial;

  @include flex(center, center, row, nowrap);
}

.wrap.masthead {
  padding: 170px 0 270px;

  @include flex(center, center, column, nowrap);

  .masthead-title {
    max-width: 728px;
    width: 100%;
    color: $c-pink-moon;
    text-align: center;
  }
}

.wrap.masthead.light {
  background: $c-almost-white;

  .masthead-title {
    color: $c-black-swan;
  }
}

.wrap.masthead.centered {
  padding-top: 119px;
  padding-bottom: 115px;
}

.wrap.simple {
  background: transparent;
  border-radius: 0;
  height: auto;
}

.wrap.small {
  padding-top: 138px;
  padding-bottom: 86px;
}

.wrap.fixed {
  padding: 0;
  height: 500px;
}

.fl-fill {
  @include square(100%);
  @include position(absolute, 0, 0, auto, auto);
}

.overlap {
  margin-top: -$mastheadOverlap !important;
  padding-top: 0 !important;
  padding-bottom: $mastheadOverlap !important;
}

.content-wrap {
  margin: 0 auto;
  max-width: $contentWidth;
  width: 100%;
}

.content-wrap.wide {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.content-wrap.slim {
  max-width: $contentWidthSlim;
}

.container.max {
  margin: 0 auto;
  padding: 60px 0;
  max-width: 1280px;
  width: 90%;
}

.page-content {
  position: relative;
}

.pad-edges {
  padding-left: $edgeMobile !important;
  padding-right: $edgeMobile !important;
}

.content-pad-edges {
  padding-left: $contentEdgeMobile !important;
  padding-right: $contentEdgeMobile !important;
}

.detail-page {
  margin: 0 auto;
  max-width: 2120px;
  width: 100%;
}

.force-overflow {
  overflow: hidden;
  perspective: 1 !important;
  -webkit-perspective: 1 !important;
  backface-visibility: none !important;
  -webkit-backface-visibility: none !important;
}

img.cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fl-image-fill {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

label.error,
.label.error {
  color: $c-mahogany !important;
}

.form-error-message {
  margin-bottom: 16px;
  display: block;
  text-align: center;
  color: $c-mahogany !important;
  width: 100%;

  @include defaultMotion(opacity);

  @media only screen and (max-width: $mobile) and (max-height: 736px) {
    margin-bottom: 8px;
  }
}

/* --- 2 | MOTION --- */

a,
button,
.button,
input,
.StripeElement,
.link {
  @include defaultMotionTriple(border, background, color);
  @include removeBrowserStyles();
}

.fade {
  opacity: 0;

  @include animate(
    fadeIn,
    0.6s,
    $easeInOutCubic,
    0s,
    initial,
    normal,
    forwards
  );
}

// KEYFRAMES

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* --- 3 | BUTTONS --- */
.button {
  padding: 0 24px;
  text-align: center;
  width: 100%;
  height: 52px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button {
  @include removeSelect();
}

.button:after {
  display: none;
}

.button.solid {
  @include btnPrimary();
}

.button.solid:hover {
  border-color: $c-black-swan-hover;
  background: $c-black-swan-hover;
}

.button.solid.light {
  @include btnPrimaryLight();
}

.button.solid.light:hover {
  background: $c-white-smoke !important;
  border-color: $c-white-smoke !important;
}

.button.border {
  color: $c-black-swan !important;
  background: $c-white-fang !important;
  @include btnSecondary();
  @include flex(center, center, row, nowrap);
}

.button.border:hover {
  border-color: $c-black-swan-hover;
  color: $c-black-swan-hover !important;
}

.button.border.light {
  @include btnSecondaryLight();
}

.button.small {
  padding: 8px 20px !important;
  height: auto;
}

.button.mini {
  padding: 1px 14px !important;
  min-width: 72px;
  width: auto;
  height: auto;
}

.button-wrap {
  position: relative;
}

button.simple,
.button.simple {
  margin: 0;
  padding: 0;
  display: inline-block !important;
  background: none;
  border: 0;
  border-radius: 0;
  width: auto;
  height: auto;
  color: $c-black-swan;
}

button.simple:hover,
.button.simple:hover {
  color: $c-black-swan-hover;
}

button.underline,
.button.underline {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  border-radius: 0;
  width: auto;
  height: auto;
  color: $c-hurricane;
  border-bottom: 1px solid $c-hurricane;
  line-height: 18px !important;
}

button.underline:hover,
.button.underline:hover {
  color: $c-black-swan;
  border-color: $c-black-swan;
}

button:disabled,
.button:disabled {
  background: $c-white-smoke;
  border-color: $c-white-smoke;
  color: $c-hurricane;
  pointer-events: none;
}

/* --- 4 | LINKS --- */

a {
  position: relative;
  color: unset;
}

a.no-underline:after {
  display: none;
}

a.simple-link {
  color: $c-denim !important;
}

a.simple-link:after {
  display: none;
}

.link {
  color: $c-black-swan !important;
  cursor: pointer;
}

.link:hover {
  color: $c-black-swan-hover !important;
}

.link.light {
  color: $c-white-fang !important;
}

.link.light:hover {
  color: $c-almost-white !important;
}

.link.warning {
  color: $c-mahogany !important;
}

.link.highlight {
  color: $c-denim !important;
}

/* --- 5 | RESPONSIVE --- */

@include mediaMin($mobile) {
  .pad-edges {
    padding-left: $edgeTablet !important;
    padding-right: $edgeTablet !important;
  }

  .content-pad-edges {
    padding-left: $contentEdgeDesktop !important;
    padding-right: $contentEdgeDesktop !important;
  }

  .container.max {
    padding: 120px 0 !important;
  }
}

@include mediaMin($tablet) {
  .wrap {
    border-radius: 12px;
    width: calc(100% - (#{$edgeTablet} * 2));
  }

  .wrap.fill {
    padding: 32px;
    min-height: $wrapperHeight;
  }
}

@include mediaBetween($mobile, calc(#{$contentWidth} + (32px * 2))) {
  .content-wrap {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

@include mediaBetween(601px, $tablet) {
  .container.max {
    width: 85%;
  }
}

@include mediaBetween($tablet, $desktop) {
  .container.max {
    width: 85%;
  }
}

@include mediaMin($desktop) {
  .wrap {
    width: calc(100% - (#{$edgeDesktop} * 2));
  }

  .pad-edges {
    padding-left: $edgeDesktop !important;
    padding-right: $edgeDesktop !important;
  }

  .container.max {
    width: 70%;
  }
}

@include mediaMin(1980px) {
  .detail-page.book-detail {
    .page-section.right {
      padding-right: 100px !important;
    }
  }
}

@media only screen and (max-height: 700px) {
  .app-content {
    min-height: initial;
  }
}

@include mediaMax($mobile) {
  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='password'],
  input[type='tel'] {
    font-size: 16px !important;
  }
}
