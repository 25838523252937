@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// SMS FORM

.sms-form {
  margin-bottom: 40px;
  width: 100%;
  position: relative;
  min-height: 160px;
  position: relative;
  z-index: 2;

  form {
    width: 100%;

    @include flex(flex-start, center, column, nowrap);
  }

  .error-message {
    margin-top: 10px;
    display: block;
    max-width: none;
    color: $c-mahogany !important;
    min-height: 16px;
    width: 100%;
    text-align: center;
    opacity: 0;
    pointer-events: none;

    @include move(0, 0);
    @include defaultMotion(opacity);
  }

  .description {
    margin-bottom: 16px;
    max-width: 327px;
    width: 100%;
    color: $c-grey-strong;
    text-align: center;
  }

  label {
    margin-top: 10px;
    max-width: 275px;
    width: 100%;
    text-align: center;
    color: $c-hurricane;

    a {
      color: $c-denim;
    }

    a:after {
      display: none;
    }
  }
}

.sms-form.simple {
  min-height: initial;

  .error-message {
    margin-top: 4px;
    margin-bottom: 0;
    width: calc(100% - 24px);
    text-align: left;

    @include move(24px, 4px);
    @include defaultMotion(opacity);
  }
}

.sms-form.error {
  form {
    .field-wrap {
      input {
        border-color: $c-mahogany !important;
      }
    }

    .error-message {
      opacity: 1;
    }
  }
}
