@import '../../../scss/colors';
@import '../../../scss/mixins';
@import '../../../scss/vars';

// RESULT LOADER
.result-loader {
  .item {
    @include flex(flex-start, center, row, nowrap);
    @include animate(fade, 0.6s, linear, 0, infinite, alternate, none);
    opacity: 0;

    .item-image {
      background: $c-white-smoke;
      border-radius: 2px;

      @include size(32px, 48px);
    }
    .item-detail {
      max-width: 260px;
      width: calc(100% - 32px);

      .item-line {
        margin-bottom: 8px;
        background: $c-white-smoke;
        border-radius: 20px;

        @include size(100%, 12px);
      }
      .item-line:last-child {
        margin-bottom: 0;
        width: 160px;
      }
    }
  }

  .item:nth-child(2) {
    @include animationDelay(0.3s);
  }

  .item:last-child {
    @include animationDelay(0.6s);
  }
}

// KEYFRAMES

@-moz-keyframes fade {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}
