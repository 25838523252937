@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// CHECKBOX
.checkbox {
  @include flex(flex-start, center, row, nowrap);

  .check-marker {
    margin-right: 10px;
    border: 1px solid $c-grey-neutral;
    border-radius: 4px;
    background: $c-white-fang;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;

    @include square(24px);
  }

  .check-marker:after {
    content: '';
    border: solid white;
    border-width: 0 3px 3px 0;
    display: none;

    @include size(5px, 10px);
    @include position(absolute, 50%, 45%, auto, auto);
    @include moveRotate(-50%, -50%, 45deg);
  }
}

.checkbox.checked {
  .check-marker {
    background: $c-denim;
    border-color: transparent;
    opacity: 1;
  }
  .check-marker:after {
    display: block;
  }
}

.checkbox.radio {
  .check-marker {
    border-radius: 50%;
    background: $c-white-fang !important;
  }
  .check-marker:after {
    border: none;
    border-radius: 50%;
    background: $c-denim;

    @include square(12px);
    @include position(absolute, 50%, 50%, auto, auto);
    @include move(-50%, -50%);
  }
}

.checkbox.radio.checked {
  .check-marker {
    border-color: $c-grey-medium;
  }
}
