@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// MAILING LIST FORM

.mailing-list-form {
  .note {
    margin-top: 20px;
    display: block;
    text-align: center;
    color: $c-grey-medium;
  }
}
