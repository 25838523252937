@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// COMMUNITY - GLOBAL STYLES

.tag {
  display: block;
  color: $c-fable-green;
  text-transform: uppercase;

  @include flex(flex-start, center, row, nowrap);

  svg {
    margin-right: 5px;
    width: 13px;
  }
}

.avatar {
  margin-right: 8px;
  display: block;

  @include square(44px);
}

.blog-post-details {
  @include flex(flex-start, center, row, nowrap);

  span {
    text-transform: uppercase;
  }

  .blog-post-author {
    margin-right: 10px;
    padding-right: 10px;
    color: $c-pink-moon;
    position: relative;
  }

  .blog-post-author:after {
    content: '';
    display: block;

    @include circle(4px, $c-grey-medium);
    @include position(absolute, auto, 50%, -2px, auto);
    @include moveY(-50%);
  }

  .blog-post-date {
    color: $c-grey-medium;
  }
}
