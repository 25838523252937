@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// NOTIFICATION

.notification {
  padding: 14px;
  max-width: 320px;
  width: 100%;
  background: $c-black-swan;
  border-radius: 8px;
  z-index: 99999;
  pointer-events: none;
  opacity: 0;

  @include flex(flex-start, center, row, nowrap);
  @include position(fixed, 50%, 16px, auto, auto);
  @include move(-50%, -100px);
  @include cardShadow();
  @include customMotionDouble(transform, opacity, ease, 0.6s);

  .notification-icon {
    margin-right: 12px;
    border-radius: 8px;
    background: $c-grey-strong;

    @include square(24px);
    @include flex(center, center, row, nowrap);

    img {
      display: block;
      width: 14px;
    }
  }

  .notification-message {
    p {
      color: $c-white-fang;
    }
  }
}

.notification.active {
  opacity: 1;
  pointer-events: auto;

  @include move(-50%, 0);
}

.notification.success {
  background: $c-shamrock;
}

@include mediaMin($tablet) {
  .notification {
    @include position(fixed, auto, calc(16px + #{$headerHeight}), 16px, auto);
    @include moveX(350px);
  }

  .notification.active {
    opacity: 1;
    @include moveX(0);
  }
}
