@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// 404 PAGE
.page-not-found {
  padding: 0 38px;
  background-color: $c-white-fang;
  min-height: 100vh;

  @include flex(center, center, row, nowrap);

  .content {
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
    text-align: center;

    .error-image {
      position: relative;

      img {
        margin-bottom: 24px;
        display: block;
        width: 100%;
      }
    }

    .error-image:after {
      content: '';
      background: url(../../assets/images/graphics/star.svg);

      @include position(absolute, auto, auto, 0, -31px);
      @include size(25px, 21px);
      @include img(contain, center);
    }

    p {
      margin: 12px auto 24px;
      color: $c-grey-strong;
      width: 234px;
    }

    button {
      margin: 0 auto;
      max-width: 200px;
      width: 100%;
    }
  }
}

// RESPONSIVE
@include mediaMin($mobile) {
  .page-not-found {
    padding: 0 92px;
  }
}

@include mediaBetween($tablet, $desktop) {
  .page-not-found {
    .content {
      .error-image:after {
        @include position(absolute, auto, auto, 0, -44px);
        @include size(31px, 27px);
      }
    }
  }
}

@include mediaMin($desktop) {
  .page-not-found {
    .content {
      .error-image:after {
        @include position(absolute, auto, auto, 0, -54px);
        @include size(39px, 34px);
      }
    }
  }
}

@media only screen and (max-height: 820px) {
  .page-not-found {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
