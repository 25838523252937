@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// MODAL
.modal-container {
  padding: 0;
  background: $c-black-swan-faded;
  z-index: 100000;
  opacity: 0;
  pointer-events: none;

  @include size(100%, 100vh);
  @include position(fixed, 0, 0, auto, auto);
  @include flex(center, flex-end, row, nowrap);
  @include defaultMotion(opacity);

  .modal-form {
    padding: 0 24px;
    width: 100%;
    pointer-events: none;
  }

  .modal {
    padding: 48px 20px;
    max-width: none;
    height: calc(100% - 100px);
    border-radius: 12px 12px 0 0;
    position: relative;
    overflow: hidden;
    width: 100%;

    @include moveY(calc(100% - 100px));
    @include defaultMotion(transform);

    .inner-content {
      padding: 40px;
      width: 100%;
    }

    .inner-content.center {
      text-align: center;
    }
  }

  .close-modal {
    cursor: pointer;
    z-index: 1;

    @include flex(center, center, row, nowrap);
    @include circle(24px, $c-almost-white);
    @include position(absolute, auto, 14px, 19px, auto);
    @include defaultMotion(opacity);
  }

  .close-modal svg {
    width: 20px !important;

    path {
      fill: $c-black-swan;
    }
  }

  .close-modal:hover {
    opacity: 0.7;
  }

  .loader {
    height: 100%;
  }
}

.modal-container.default {
  height: 100% !important;

  .modal {
    padding: 0 !important;
    padding-top: 30px !important;

    .modal-title {
      text-align: center;
    }

    .modal-content {
      padding: 20px 20px 150px;
      max-width: none;
      width: 100%;
      height: calc(100% - 22px);
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none;
      will-change: scroll-position;
    }

    .modal-content::-webkit-scrollbar {
      display: none;
    }

    .modal-content:after {
      content: '';
      background: linear-gradient(180deg, rgba(255, 255, 255, 0), white);
      width: 100%;
      height: 150px;
      position: fixed;
      left: 0;
      top: auto;
      right: auto;
      bottom: 0;
      pointer-events: none;

      @include position(fixed, 0, auto, auto, 0);
    }

    // LIST
    .modal-content.list {
      padding: 0 0 18px !important;
      width: 100%;

      .modal-item {
        padding: 16px 30px;
        cursor: pointer;

        @include flex(flex-start, center, row, nowrap);
        @include defaultMotion(background);

        img {
          margin-right: 12px;
        }
        p {
          cursor: pointer;
        }
      }

      .modal-item:hover {
        background: $c-almost-white;
      }
    }

    .cancel-modal {
      padding: 20px 30px;
      border-top: 1px solid $c-white-smoke;
    }
  }
}

.modal-container.default.open {
  opacity: 1;
  pointer-events: auto;
  background: $c-black-swan-faded;

  .modal {
    @include moveY(0);
  }
}

// CARD
.modal-container.m-card {
  padding: 20px;
  align-items: center;

  .modal {
    height: auto;
    border-radius: 12px;

    @include moveY(0);
    @include removeMotion();
  }
}

.modal-container.m-card .modal-content:after {
  display: none;
}

// STATIC
.modal-container.static {
  padding: 0 8px;
  background: none;
  opacity: 1;
  pointer-events: auto;
  text-align: center;
  z-index: 1;

  @include size(auto, auto);
  @include flex(center, center, row, nowrap);
  @include position(relative, auto, auto, auto, auto);

  .modal {
    height: auto !important;
    border-radius: 12px !important;
    @include forceMoveY(0);
  }
}

// FULL
.modal-container.m-full .modal {
  padding-top: 0 !important;
  width: 100% !important;
  max-width: none !important;
  height: 100% !important;
  border-radius: 0 !important;

  .close-modal {
    top: 16px;
    right: 16px;
    background: transparent;
    overflow: hidden;

    svg {
      position: relative;

      path {
        fill: $c-white-fang;
      }
    }
  }

  .close-modal:before {
    content: '';
    width: 100%;
    height: 100%;
    background: $c-black-swan;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@include mediaMax($mobile) {
  .modal-container.m-card.m-mob-full {
    padding: 0;

    .modal {
      padding-top: 0 !important;
      width: 100% !important;
      max-width: none !important;
      height: 100% !important;
      border-radius: 0 !important;
    }
  }
}

// RESPONSIVE

@include mediaBetween($mobileS, $mobile) {
  .modal-container.static {
    padding: 0 24px !important;
  }
}

@include mediaMin($mobile) {
  .modal-container {
    align-items: center !important;

    .modal {
      padding: 48px !important;
      max-width: 90% !important;
      width: auto !important;
      border-radius: 12px !important;
      height: auto !important;

      @include forceMoveY(0);

      .modal-content {
        padding: 60px !important;
        padding-top: 0 !important;
        height: auto !important;
        overflow: initial !important;
      }

      .modal-content:after {
        display: none;
      }

      .modal-content.list {
        width: 336px !important;
      }

      .inner-content {
        width: 480px;
      }
    }
  }
}

@media only screen and (max-width: $mobile) and (max-height: 736px) {
  .modal-container.auth {
    .modal-form {
      .field-wrap.has-label {
        margin-bottom: 12px;
      }
    }
  }
}

@media only screen and (max-height: 650px) {
  .modal-container.static {
    height: 650px !important;
  }
}

@media only screen and (max-height: 790px) {
  .modal-container.m-card {
    height: 100% !important;
  }
}

@include mediaMax($mobile) {
  .modal-container.auth {
    height: 100% !important;

    .modal-form {
      padding: 0;
      height: 100%;
    }
  }
}

@media only screen and (max-width: $mobileS) and (max-height: 700px) {
  .modal-container.auth {
    background: $c-white-fang !important;
    height: 100% !important;
    align-items: flex-start;
    overflow-y: scroll;

    .modal-form {
      height: 700px;
    }
  }
}
