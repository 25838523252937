$text-prefix: 'fds-text-';
$bg-prefix: 'fds-bg-';

// PRIMARY
$c-fable-green: #064c37;
$c-black-swan: #161015;
$c-pink-moon: #e4adbb;
$c-white-fang: #fff;

// SECONDARY
$c-saffron: #f4c635;
$c-mahogany: #cb3f3f;
$c-tussock: #c19c4e;
$c-shamrock: #0a8e4f;
$c-lavender-blush: #ffeaef;
$c-denim: #116ecd;
$c-maya-blue: #73d5ff;

// NEUTRAL
$c-hurricane: #847f7c;
$c-whisper: #e9e1de;
$c-white-smoke: #ededed;
$c-almost-white: #f9f9f9;

// OTHER
$c-fable-green-dark: #004a33;
$c-fable-green-darker: #053829;
$c-black-swan-hover: #332e32;
$c-black-swan-faded: rgba(22, 16, 21, 0.5);
$c-shamrock-light: #9ae2bf;
$c-faded-blue: #0c4357;
$c-grey: #545454;
$c-grey-medium: #d1cfd0;
$c-grey-dark: #716d70;
$c-grey-strong: #2e272d;
$c-grey-neutral: #bcbcbc;
$c-border-grey: #ececec;
$c-search-input-hover: #dadada;
$c-orange: #ef8132;
