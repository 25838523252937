// SETTINGS
$bannerDisabled: true;

// MOTION
$defaultSpeed: 0.2s;
$easeInOutCubic: cubic-bezier(0.65, 0, 0.35, 1);
$easeOutQuad: cubic-bezier(0.5, 1, 0.89, 1);
$easeInOutQuad: cubic-bezier(0.45, 0, 0.55, 1);
$easeInOutQuint: cubic-bezier(0.83, 0, 0.17, 1);
$easeInOutSine: cubic-bezier(0.37, 0, 0.63, 1);

// LAYOUT
$headerHeight: 60px;
$subheaderHeight: 52px;

$defaultMargin: 32px;
$smallMargin: 4px;

$bannerHeightBig: if($bannerDisabled, 0px, 188px);
$bannerHeightSmall: if($bannerDisabled, 0px, 182px);

$mastheadTopBig: if($bannerDisabled, bottom, 160px);
$mastheadTopSmall: if($bannerDisabled, bottom, 330px);

$wrapperHeight: calc(100vh - #{$headerHeight} - #{$defaultMargin});
$wrapperHeightBig: calc(
  100vh - #{$headerHeight} - #{$defaultMargin} - #{$bannerHeightBig} - #{$defaultMargin}
);
$wrapperHeightSmall: calc(
  100vh - #{$headerHeight} - #{$bannerHeightSmall} - $smallMargin
);

$edgeDesktop: 32px;
$edgeTablet: 20px;
$edgeMobile: 8px;

$contentEdgeDesktop: 32px;
$contentEdgeMobile: 24px;

$contentWidth: 1072px;
$contentWidthSlim: 612px;

$mastheadOverlap: 170px;

// RESPONSIVE
$mobileXS: '321px';
$mobileS: '376px';
$mobile: '548px';
$tablet: '768px';
$desktop: '1152px';
$desktopL: '1440px';
$desktopXL: '1680px';
