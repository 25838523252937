@import '../../../scss/colors';
@import '../../../scss/mixins';
@import '../../../scss/vars';

// RESULT CATEGORIES

.item {
  padding: 8px 20px;
  width: 100%;
  cursor: pointer !important;

  @include flex(flex-start, center, row, nowrap);
  @include defaultMotion(background);

  .item-image {
    margin-right: 16px;
    overflow: hidden;
    position: relative;
    background-color: $c-white-smoke !important;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;

      @include defaultMotion(opacity);
    }

    .loader {
      height: 100%;

      svg {
        @include forceSquare(24px);
      }
    }
  }

  .item-image.loaded {
    img {
      opacity: 1;
    }
  }

  .item-detail {
    p {
      cursor: pointer !important;
    }

    p.item-title {
      line-height: 20px !important;
    }

    p.item-title.sub {
      margin-top: 0;
      color: $c-hurricane !important;
    }
  }
}

.item:hover {
  background: $c-almost-white;
}
