@import 'colors';
@import 'vars';

/*

INDEX

0 | LAYOUT
1 | MOTION
2 | TYPOGRAPHY
3 | BUTTONS
4 | EFFECTS
5 | MEDIA
6 | OTHER


*/

/* --- 0 | LAYOUT --- */

// flexbox
@mixin flex($justify, $align, $direction, $wrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

@mixin inlineFlex($justify, $align, $direction, $wrap) {
  display: inline-flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

@mixin flexForce($justify, $align, $direction, $wrap) {
  display: flex !important;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

// size
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

// size
@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin circle($size, $color) {
  width: $size;
  height: $size;
  border-radius: 50%;
  background: $color;
}

@mixin forceSquare($size) {
  width: $size !important;
  height: $size !important;
}

// position
@mixin position($pos, $left, $top, $right, $bottom) {
  position: $pos;
  left: $left;
  top: $top;
  right: $right;
  bottom: $bottom;
}

// background image
@mixin img($size, $position) {
  background-size: $size !important;
  background-position: $position !important;
  background-repeat: no-repeat !important;
}

@mixin imgWithURL($url, $size, $position) {
  background-image: url($url);
  background-size: $size;
  background-position: $position;
  background-repeat: no-repeat;
}

// TRANSFORM

// move
@mixin move($x, $y) {
  -o-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -webkit-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin forceMove($x, $y) {
  -o-transform: translate($x, $y) !important;
  -ms-transform: translate($x, $y) !important;
  -moz-transform: translate($x, $y) !important;
  -webkit-transform: translate($x, $y) !important;
  transform: translate($x, $y) !important;
}

@mixin moveX($x) {
  -o-transform: translateX($x);
  -ms-transform: translateX($x);
  -moz-transform: translateX($x);
  -webkit-transform: translateX($x);
  transform: translateX($x);
}

@mixin forceMoveX($x) {
  -o-transform: translateX($x) !important;
  -ms-transform: translateX($x) !important;
  -moz-transform: translateX($x) !important;
  -webkit-transform: translateX($x) !important;
  transform: translateX($x) !important;
}

@mixin moveY($y) {
  -o-transform: translateY($y);
  -ms-transform: translateY($y);
  -moz-transform: translateY($y);
  -webkit-transform: translateY($y);
  transform: translateY($y);
}

@mixin forceMoveY($y) {
  -o-transform: translateY($y) !important;
  -ms-transform: translateY($y) !important;
  -moz-transform: translateY($y) !important;
  -webkit-transform: translateY($y) !important;
  transform: translateY($y) !important;
}

@mixin moveZ($z) {
  -o-transform: translateZ($z);
  -ms-transform: translateZ($z);
  -moz-transform: translateZ($z);
  -webkit-transform: translateZ($z);
  transform: translateZ($z);
}

@mixin forceMoveZ($z) {
  -o-transform: translateZ($z) !important;
  -ms-transform: translateZ($z) !important;
  -moz-transform: translateZ($z) !important;
  -webkit-transform: translateZ($z) !important;
  transform: translateZ($z) !important;
}

@mixin move3d() {
  -o-transform: translate3d(0, 0, 0) !important;
  -ms-transform: translate3d(0, 0, 0) !important;
  -moz-transform: translate3d(0, 0, 0) !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}

// rotate
@mixin rotate($r) {
  -o-transform: rotate($r);
  -ms-transform: rotate($r);
  -moz-transform: rotate($r);
  -webkit-transform: rotate($r);
  transform: rotate($r);
}

@mixin moveRotate($x, $y, $r) {
  -o-transform: translate($x, $y) rotate($r);
  -ms-transform: translate($x, $y) rotate($r);
  -moz-transform: translate($x, $y) rotate($r);
  -webkit-transform: translate($x, $y) rotate($r);
  transform: translate($x, $y) rotate($r);
}

// scale
@mixin scale($s) {
  -o-transform: scale($s);
  -ms-transform: scale($s);
  -moz-transform: scale($s);
  -webkit-transform: scale($s);
  transform: scale($s);
}

@mixin scaleFlip($s1, $s2) {
  -o-transform: scale($s1, $s2);
  -ms-transform: scale($s1, $s2);
  -moz-transform: scale($s1, $s2);
  -webkit-transform: scale($s1, $s2);
  transform: scale($s1, $s2);
}

@mixin moveAndScale($x, $y, $s) {
  -o-transform: translate($x, $y) scale($s);
  -ms-transform: translate($x, $y) scale($s);
  -moz-transform: translate($x, $y) scale($s);
  -webkit-transform: translate($x, $y) scale($s);
  transform: translate($x, $y) scale($s);
}

@mixin forceMoveAndScale($x, $y, $s) {
  -o-transform: translate($x, $y) scale($s) !important;
  -ms-transform: translate($x, $y) scale($s) !important;
  -moz-transform: translate($x, $y) scale($s) !important;
  -webkit-transform: translate($x, $y) scale($s) !important;
  transform: translate($x, $y) scale($s) !important;
}

// parallax
@mixin perspective($p, $o) {
  -o-perspective: $p;
  -ms-perspective: $p;
  -moz-perspective: $p;
  -webkit-perspective: $p;
  perspective: $p;
  -o-perspective-origin: $o;
  -ms-perspective-origin: $o;
  -moz-perspective-origin: $o;
  -webkit-perspective-origin: $o;
  perspective-origin: $o;
}

@mixin parallaxPos($x, $y, $z, $s) {
  -o-transform: translate($x, $y) translateZ($z) scale($s);
  -ms-transform: translate($x, $y) translateZ($z) scale($s);
  -moz-transform: translate($x, $y) translateZ($z) scale($s);
  -webkit-transform: translate($x, $y) translateZ($z) scale($s);
  transform: translate($x, $y) translateZ($z) scale($s);
}

@mixin forceParallaxPos($x, $y, $z, $s) {
  -o-transform: translate($x, $y) translateZ($z) scale($s) !important;
  -ms-transform: translate($x, $y) translateZ($z) scale($s) !important;
  -moz-transform: translate($x, $y) translateZ($z) scale($s) !important;
  -webkit-transform: translate($x, $y) translateZ($z) scale($s) !important;
  transform: translate($x, $y) translateZ($z) scale($s) !important;
}

/* --- 1 | MOTION --- */

// transition
@mixin customMotion($element, $easing, $speed) {
  -o-transition: $element $easing $speed;
  -ms-transition: $element $easing $speed;
  -moz-transition: $element $easing $speed;
  -webkit-transition: $element $easing $speed;
  transition: $element $easing $speed;
}

@mixin forceCustomMotion($element, $easing, $speed) {
  -o-transition: $element $easing $speed !important;
  -ms-transition: $element $easing $speed !important;
  -moz-transition: $element $easing $speed !important;
  -webkit-transition: $element $easing $speed !important;
  transition: $element $easing $speed !important;
}

@mixin customMotionDouble($attr1, $attr2, $easing, $speed) {
  -o-transition: $attr1 $easing $speed, $attr2 $easing $speed;
  -ms-transition: $attr1 $easing $speed, $attr2 $easing $speed;
  -moz-transition: $attr1 $easing $speed, $attr2 $easing $speed;
  -webkit-transition: $attr1 $easing $speed, $attr2 $easing $speed;
  transition: $attr1 $easing $speed, $attr2 $easing $speed;
}

@mixin customMotionDoubleExact(
  $attr1,
  $easing1,
  $speed1,
  $attr2,
  $easing2,
  $speed2
) {
  -o-transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2;
  -ms-transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2;
  -moz-transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2;
  -webkit-transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2;
  transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2;
}

@mixin customMotionTripleExact(
  $attr1,
  $easing1,
  $speed1,
  $attr2,
  $easing2,
  $speed2,
  $attr3,
  $easing3,
  $speed3
) {
  -o-transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2,
    $attr3 $easing3 $speed3;
  -ms-transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2,
    $attr3 $easing3 $speed3;
  -moz-transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2,
    $attr3 $easing3 $speed3;
  -webkit-transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2,
    $attr3 $easing3 $speed3;
  transition: $attr1 $easing1 $speed1, $attr2 $easing2 $speed2,
    $attr3 $easing3 $speed3;
}

@mixin customMotionDoubleExactWithDelay(
  $attr1,
  $easing1,
  $speed1,
  $delay1,
  $attr2,
  $easing2,
  $speed2,
  $delay2
) {
  -o-transition: $attr1 $easing1 $speed1 $delay1,
    $attr2 $easing2 $speed2 $delay2;
  -ms-transition: $attr1 $easing1 $speed1 $delay1,
    $attr2 $easing2 $speed2 $delay2;
  -moz-transition: $attr1 $easing1 $speed1 $delay1,
    $attr2 $easing2 $speed2 $delay2;
  -webkit-transition: $attr1 $easing1 $speed1 $delay1,
    $attr2 $easing2 $speed2 $delay2;
  transition: $attr1 $easing1 $speed1 $delay1, $attr2 $easing2 $speed2 $delay2;
}

@mixin defaultMotion($attr) {
  -o-transition: $attr ease $defaultSpeed;
  -ms-transition: $attr ease $defaultSpeed;
  -moz-transition: $attr ease $defaultSpeed;
  -webkit-transition: $attr ease $defaultSpeed;
  transition: $attr ease $defaultSpeed;
}

@mixin defaultMotionDouble($attr1, $attr2) {
  -o-transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed;
  -ms-transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed;
  -moz-transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed;
  -webkit-transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed;
  transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed;
}

@mixin defaultMotionTriple($attr1, $attr2, $attr3) {
  -o-transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed,
    $attr3 ease $defaultSpeed;
  -ms-transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed,
    $attr3 ease $defaultSpeed;
  -moz-transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed,
    $attr3 ease $defaultSpeed;
  -webkit-transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed,
    $attr3 ease $defaultSpeed;
  transition: $attr1 ease $defaultSpeed, $attr2 ease $defaultSpeed,
    $attr3 ease $defaultSpeed;
}

@mixin motionDelay($delay) {
  -o-transition-delay: $delay;
  -ms-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin removeMotion() {
  -o-transition: none !important;
  -ms-transition: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}

@mixin animate(
  $name,
  $duration,
  $easing,
  $delay,
  $iteration,
  $direction,
  $fill
) {
  -o-animation-name: $name;
  -ms-animation-name: $name;
  -moz-animation-name: $name;
  -webkit-animation-name: $name;
  animation-name: $name;

  -o-animation-duration: $duration;
  -ms-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -webkit-animation-duration: $duration;
  animation-duration: $duration;

  -o-animation-timing-function: $easing;
  -ms-animation-timing-function: $easing;
  -moz-animation-timing-function: $easing;
  -webkit-animation-timing-function: $easing;
  animation-timing-function: $easing;

  -o-animation-delay: $delay;
  -ms-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -webkit-animation-delay: $delay;
  animation-delay: $delay;

  -o-animation-iteration-count: $iteration;
  -ms-animation-iteration-count: $iteration;
  -moz-animation-iteration-count: $iteration;
  -webkit-animation-iteration-count: $iteration;
  animation-iteration-count: $iteration;

  -o-animation-direction: $direction;
  -ms-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -webkit-animation-direction: $direction;
  animation-direction: $direction;

  -o-animation-fill-mode: $fill;
  -ms-animation-fill-mode: $fill;
  -moz-animation-fill-mode: $fill;
  -webkit-animation-fill-mode: $fill;
  animation-fill-mode: $fill;
}

@mixin animationDelay($delay) {
  -o-animation-delay: $delay;
  -ms-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin removeAnimation() {
  -o-animation: none;
  -ms-animation: none;
  -moz-animation: none;
  -webkit-animation: none;
  animation: none;
}

/* --- 3 | BUTTONS --- */

// button primary
@mixin btnPrimary() {
  color: $c-white-fang;
  background: $c-black-swan;
  border-radius: 60px;
  border: 1px solid $c-black-swan;
}

// button primary light
@mixin btnPrimaryLight() {
  color: $c-black-swan;
  background: $c-white-fang;
  border-radius: 60px;
  border: 1px solid $c-white-fang;
}

// button secondary
@mixin btnSecondary() {
  color: $c-black-swan;
  background: transparent;
  border-radius: 60px;
  border: 1px solid $c-black-swan;
}

// button secondary light
@mixin btnSecondaryLight() {
  color: $c-white-fang !important;
  background: transparent;
  border-radius: 60px;
  border: 1px solid $c-white-fang;
}

// button mini
@mixin btnMiniMobile() {
  font-size: 12px !important;
  line-height: 22px !important;
}

@mixin btnMiniDesktop() {
  font-size: 14px !important;
  line-height: 24px !important;
}

/* --- 4 | EFFECTS --- */

@mixin gradient($theme, $direction, $percent) {
  $gradient: null;

  @if $theme == light {
    $gradient: linear-gradient(
      to $direction,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) $percent
    );
  } @else if $theme == dark {
    $gradient: linear-gradient(
      to $direction,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) $percent
    );
  }

  -o-background: $gradient;
  -ms-background: $gradient;
  -moz-background: $gradient;
  -webkit-background: $gradient;
  background: $gradient;
}

@mixin inputShadow() {
  -o-box-shadow: 0 4px 14px #f5f5f5;
  -ms-box-shadow: 0 4px 14px #f5f5f5;
  -moz-box-shadow: 0 4px 14px #f5f5f5;
  -webkit-box-shadow: 0 4px 14px #f5f5f5;
  box-shadow: 0 4px 14px #f5f5f5;
}

@mixin searchInputShadow() {
  -o-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03);
  -ms-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03);
}

@mixin forceSearchInputShadow() {
  -o-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03) !important;
  -ms-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03) !important;
  -moz-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03) !important;
  -webkit-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03) !important;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03) !important;
}

@mixin bookShadow() {
  -o-box-shadow: 0px 2.39404px 2.19453px rgba(0, 0, 0, 0.0282725),
    0px 4.50776px 4.13211px rgba(0, 0, 0, 0.035),
    0px 8.04107px 7.37098px rgba(0, 0, 0, 0.0417275),
    0px 15.0399px 13.7866px rgba(0, 0, 0, 0.0503198),
    0px 20px 33px rgba(0, 0, 0, 0.09);
  -ms-box-shadow: 0px 2.39404px 2.19453px rgba(0, 0, 0, 0.0282725),
    0px 4.50776px 4.13211px rgba(0, 0, 0, 0.035),
    0px 8.04107px 7.37098px rgba(0, 0, 0, 0.0417275),
    0px 15.0399px 13.7866px rgba(0, 0, 0, 0.0503198),
    0px 20px 33px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 2.39404px 2.19453px rgba(0, 0, 0, 0.0282725),
    0px 4.50776px 4.13211px rgba(0, 0, 0, 0.035),
    0px 8.04107px 7.37098px rgba(0, 0, 0, 0.0417275),
    0px 15.0399px 13.7866px rgba(0, 0, 0, 0.0503198),
    0px 20px 33px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0px 2.39404px 2.19453px rgba(0, 0, 0, 0.0282725),
    0px 4.50776px 4.13211px rgba(0, 0, 0, 0.035),
    0px 8.04107px 7.37098px rgba(0, 0, 0, 0.0417275),
    0px 15.0399px 13.7866px rgba(0, 0, 0, 0.0503198),
    0px 20px 33px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 2.39404px 2.19453px rgba(0, 0, 0, 0.0282725),
    0px 4.50776px 4.13211px rgba(0, 0, 0, 0.035),
    0px 8.04107px 7.37098px rgba(0, 0, 0, 0.0417275),
    0px 15.0399px 13.7866px rgba(0, 0, 0, 0.0503198),
    0px 20px 33px rgba(0, 0, 0, 0.09);
}

@mixin bookShadowLarge() {
  -o-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
}

@mixin cardShadow() {
  -o-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

@mixin verticalCardShadow() {
  -o-box-shadow: 0px 36px 40px rgba(196, 196, 196, 0.12);
  -ms-box-shadow: 0px 36px 40px rgba(196, 196, 196, 0.12);
  -moz-box-shadow: 0px 36px 40px rgba(196, 196, 196, 0.12);
  -webkit-box-shadow: 0px 36px 40px rgba(196, 196, 196, 0.12);
  box-shadow: 0px 36px 40px rgba(196, 196, 196, 0.12);
}

@mixin verticalCardShadowLarge() {
  -o-box-shadow: 0px 26.1071px 29.0079px rgba(196, 196, 196, 0.12);
  -ms-box-shadow: 0px 26.1071px 29.0079px rgba(196, 196, 196, 0.12);
  -moz-box-shadow: 0px 26.1071px 29.0079px rgba(196, 196, 196, 0.12);
  -webkit-box-shadow: 0px 26.1071px 29.0079px rgba(196, 196, 196, 0.12);
  box-shadow: 0px 26.1071px 29.0079px rgba(196, 196, 196, 0.12);
}

@mixin elevatedShadow() {
  -o-box-shadow: 0 30px 40px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 30px 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 30px 40px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 30px 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.05);
}

@mixin elevatedShadowLarge() {
  -o-box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
}

@mixin panelShadow() {
  -o-box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
}

@mixin panelShadowLight() {
  -o-box-shadow: -8px 0px 40px rgba(237, 237, 237, 0.5);
  -ms-box-shadow: -8px 0px 40px rgba(237, 237, 237, 0.5);
  -moz-box-shadow: -8px 0px 40px rgba(237, 237, 237, 0.5);
  -webkit-box-shadow: -8px 0px 40px rgba(237, 237, 237, 0.5);
  box-shadow: -8px 0px 40px rgba(237, 237, 237, 0.5);
}

@mixin mobileShadow() {
  -o-box-shadow: 0px 36px 46px 8px rgba(196, 196, 196, 0.12);
  -ms-box-shadow: 0px 36px 46px 8px rgba(196, 196, 196, 0.12);
  -moz-box-shadow: 0px 36px 46px 8px rgba(196, 196, 196, 0.12);
  -webkit-box-shadow: 0px 36px 46px 8px rgba(196, 196, 196, 0.12);
  box-shadow: 0px 36px 46px 8px rgba(196, 196, 196, 0.12);
}

@mixin dropShadow($x, $y, $b, $a) {
  -o-box-shadow: $x $y $b rgba(0, 0, 0, $a);
  -ms-box-shadow: $x $y $b rgba(0, 0, 0, $a);
  -moz-box-shadow: $x $y $b rgba(0, 0, 0, $a);
  -webkit-box-shadow: $x $y $b rgba(0, 0, 0, $a);
  box-shadow: $x $y $b rgba(0, 0, 0, $a);
}

@mixin headerShadow() {
  -o-box-shadow: 0px 1px 0px $c-white-smoke;
  -ms-box-shadow: 0px 1px 0px $c-white-smoke;
  -moz-box-shadow: 0px 1px 0px $c-white-smoke;
  -webkit-box-shadow: 0px 1px 0px $c-white-smoke;
  box-shadow: 0px 1px 0px $c-white-smoke;
}

@mixin removeShadow() {
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* --- 5 | MEDIA --- */
@mixin mediaMax($b) {
  @media only screen and (max-width: $b) {
    @content;
  }
}

@mixin mediaMin($b) {
  @media only screen and (min-width: $b) {
    @content;
  }
}

@mixin mediaBetween($b1, $b2) {
  @media only screen and (min-width: $b1) and (max-width: $b2) {
    @content;
  }
}

/* --- 6 | OTHER --- */

@mixin fill($color, $opacity) {
  background: $color;
  opacity: $opacity;

  @include square(100%);
  @include position(absolute, 0, 0, auto, auto);
}

@mixin menuChevron() {
  content: '';
  background: $c-white-fang;
  border-radius: 2px 0 0 0;
  border: 1px solid #ededed;
  border-bottom: transparent;
  border-right: transparent;

  @include square(12px);
  @include position(absolute, 50%, 14px, auto, auto);
  @include moveRotate(-50%, 0, 45deg);
}

@mixin menuChevronReverse() {
  content: '';
  background: $c-white-fang;
  border-radius: 2px 0 0 0;
  border: 1px solid #ededed;
  border-bottom: transparent;
  border-right: transparent;

  @include square(12px);
  @include position(absolute, 50%, auto, auto, -6px);
  @include moveRotate(-50%, 0, -135deg);
}

@mixin removeSelect() {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@mixin removeBrowserStyles() {
  -o-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

@mixin forceOverflow() {
  overflow: hidden;
  perspective: 1 !important;
  -webkit-perspective: 1 !important;
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden !important;
}

@mixin useHardwareAcceleration() {
  @include forceOverflow();
  @include move3d();
}

@mixin buttonReset {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background: none;
  background-color: none;
}
