@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// TOOLTIP

.tooltip {
  margin-left: 4px;
  position: relative;
  cursor: pointer;

  svg {
    @include square(100%);

    path {
      fill: $c-grey-medium;

      @include defaultMotion(fill);
    }
  }

  label {
    padding: 16px 20px;
    display: block;
    border-radius: 12px;
    background: $c-white-fang;
    color: $c-hurricane !important;
    min-width: 256px;
    text-align: center;
    z-index: 1;
    opacity: 0;
    pointer-events: none;

    @include bookShadow();
    @include position(absolute, 50%, auto, auto, 46px);
    @include move(-50%, 10px);
    @include defaultMotionDouble(opacity, transform);
  }

  label:after {
    border: none !important;

    @include menuChevronReverse();
  }

  label.dark {
    background: $c-black-swan;
    color: $c-white-fang !important;
    font-weight: 600;
  }

  label.dark:after {
    background: $c-black-swan;
  }
}

.tooltip:hover {
  svg path {
    fill: $c-grey-dark;
  }

  label {
    opacity: 1;

    @include move(-50%, 0);
  }
}

// DARK

.tooltip.dark {
  svg path {
    fill: $c-white-fang;
  }
}

.tooltip.dark:hover {
  svg path {
    fill: $c-white-smoke;
  }
}

// POSITION

.tooltip.small {
  @include square(16px);
}

.tooltip.medium {
  @include square(24px);
}

.tooltip.fixed {
  @include position(absolute, 0, 50%, auto, auto);
  @include moveY(-50%);
}

.tooltip.fixed.right {
  @include position(absolute, auto, 50%, 0, auto);
  @include moveY(-50%);

  label {
    @include position(absolute, auto, auto, -17px, 54px);
    @include moveY(10px);

    @include cardShadow();
  }

  label:after {
    left: calc(100% - 30px);
  }
}

.tooltip.fixed.right:hover {
  label {
    @include moveY(0);
  }
}
