@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/vars';

// LINK SENT FORM

.sms-sent {
  padding: 8px;
  padding-top: 20vh;
  min-width: initial;
  width: 100%;
  min-height: initial;

  @include flex(center, center, column, nowrap);

  .icon {
    display: block;
    width: 55px;
  }

  .title {
    margin: 18px 0 13px;
    text-align: center;
  }

  .description {
    max-width: 249px;
    width: 100%;
    text-align: center;
    color: $c-grey-strong;

    a {
      color: $c-denim;
    }

    a:hover {
      color: $c-black-swan-hover;
    }

    a:after {
      display: none;
    }
  }

  .button {
    max-width: 180px;
    width: 100%;
    background: $c-grey-strong;
  }
}

.app-link-sent {
  .title {
    margin: 0 0 4px;
  }

  .subtitle,
  .description,
  .description a {
    color: $c-grey-strong;
  }

  .subtitle {
    text-align: center;
  }

  .image {
    margin: 40px 0 25px;
    width: 130px;
  }

  .description {
    margin-bottom: 42px;
    max-width: 344px;

    a {
      padding-bottom: 1px;
      border-bottom: 1px solid $c-grey-strong;
    }

    a:hover {
      opacity: 0.8;
    }
  }
}

// RESPONSIVE
@include mediaMax($mobile) {
  .app-link-sent {
    padding: 24px 24px 40px;

    .image {
      margin: 20px 0;
      width: 110px;
    }

    .description {
      margin-bottom: 32px;
    }
  }
}

@include mediaMin($mobile) {
  .sms-sent {
    padding-top: 0;
    min-width: 520px;
    min-height: 616px;
  }

  .app-link-sent {
    min-height: 556px !important;
  }
}
