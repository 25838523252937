@import '../../../scss/colors';
@import '../../../scss/mixins';
@import '../../../scss/vars';

// RESULT LIST

.result-list {
  margin-top: 20px;

  .label {
    padding-left: 20px !important;
    color: $c-hurricane !important;
  }

  .items.book-results .item-detail {
    width: calc(100% - 32px);
  }

  .items.author-results .item-image {
    border-radius: 50%;

    @include square(40px);
  }

  .items.author-results .item-detail {
    width: calc(100% - 40px);
  }
}

.result-list:first-child {
  margin-top: 0;
}

.result-list:last-child {
  padding-bottom: 24px;
}
